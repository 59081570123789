
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { Poppins, Kanit, Roboto } from 'next/font/google';
import '@styles/globals.css';
import '@styles/sass/main.scss';
import { AppProps } from 'next/app';
export const poppins = Poppins({
    subsets: ['latin'],
    weight: ['400', '700'],
    display: 'swap',
    variable: '--font-poppins',
});
export const kanit = Kanit({
    subsets: ['thai'],
    weight: ['400', '700'],
    display: 'swap',
    variable: '--font-kanit',
});
export const roboto = Roboto({
    subsets: ['latin'],
    weight: ['400', '700'],
    display: 'swap',
    variable: '--font-roboto',
});
export const fontFamilyMap = {
    th: `${poppins.style.fontFamily}, ${kanit.style.fontFamily}`,
    en: `${poppins.style.fontFamily}, ${kanit.style.fontFamily}`,
    vi: roboto.style.fontFamily,
    id: poppins.style.fontFamily,
    'en-PH': poppins.style.fontFamily,
    'en-SG': poppins.style.fontFamily,
    'en-MY': poppins.style.fontFamily,
};
function MyApp({ Component, pageProps }: AppProps) {
    return <Component {...pageProps}/>;
}
const __Next_Translate__Page__195fb3e21db__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__195fb3e21db__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  